/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCDUixNtdJfV1cDyrkQlGUKY1ZerjeTLxo",
  "appId": "1:834206435296:web:4d07c4c8c55191eb634d85",
  "authDomain": "schooldog-i-calhoun-ga.firebaseapp.com",
  "measurementId": "G-GZV7XXTD3Q",
  "messagingSenderId": "834206435296",
  "project": "schooldog-i-calhoun-ga",
  "projectId": "schooldog-i-calhoun-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-calhoun-ga.appspot.com"
}
